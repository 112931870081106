import { useAuthHeader } from 'state/AuthState';
import axios from 'axios';
import { useErrorHandler } from 'api/controllers/ErrorHandler';
import { Region } from 'api/models/Region';

interface IGetCustomersProps {
  offset: number;
  limit: number;
}

interface IGetCustomerByIdProps {
  id: string;
}

interface IGetCustomerScheduledToursProps {
  id: string;
  direction?: string;
  startDate?: string;
  endDate?: string;
  timestamp?: string;
  includePassengers?: boolean;
}

interface IGetCustomerInvoices {
  id: string;
  year: string;
}

export const useCustomersAPI = () => {
  const authHeader = useAuthHeader();
  const errorHandler = useErrorHandler();

  const checkAuth = async () => {
    if (!authHeader) {
      throw new Error('401');
    }
  };

  const getCustomerById = (props: IGetCustomerByIdProps) => {
    return checkAuth().then(() => {
      return axios.get(`/api/customers-new/${props.id}`, { ...authHeader });
    });
  };

  const getCustomerOrders = (props: IGetCustomerScheduledToursProps & Partial<IGetCustomersProps>) => {
    return checkAuth().then(() => {
      const qs = new URLSearchParams(
        [
          ['offset', `${props.offset || 0}`],
          ['limit', `${props.limit || 25}`],
          props.direction && ['direction', props.direction],
          props.startDate && ['startDate', props.startDate],
          props.endDate && ['endDate', props.endDate],
          props.timestamp && ['timestamp', props.timestamp],
          !!props.includePassengers && ['includePassengers', true],
        ].filter(Boolean) as string[][],
      ).toString();
      return axios.get(`/api/customers/${props.id}/orders?${qs}`, { ...authHeader });
    });
  };

  const getCustomerScheduledTours = (props: IGetCustomerScheduledToursProps & Partial<IGetCustomersProps>) => {
    return checkAuth().then(() => {
      const qs = new URLSearchParams(
        [
          ['offset', `${props.offset || 0}`],
          ['limit', `${props.limit || 25}`],
          props.direction && ['direction', props.direction],
          props.startDate && ['startDate', props.startDate],
          props.endDate && ['endDate', props.endDate],
          props.timestamp && ['timestamp', props.timestamp],
        ].filter(Boolean) as string[][],
      ).toString();
      return axios.get(`/api/customers/${props.id}/scheduled-tours?${qs}`, { ...authHeader });
    });
  };

  const deleteCustomer = (id: string) => {
    return checkAuth().then(() => {
      return axios.delete(`/api/customers/${id}`, { ...authHeader });
    });
  };

  const getCustomerInvoices = ({ id, ...props }: IGetCustomerInvoices) => {
    return checkAuth().then(() => {
      const qs = new URLSearchParams(Object.entries(props)).toString();
      return axios.get(`/api/customers/${id}/invoices?${qs}`, { ...authHeader });
    });
  };

  // NEW API ENDPOINTS
  const getCustomersForSelect = async (props: { region: Region }) => {
    return checkAuth().then(() => {
      return axios.post(`/api/customers-new/for_selects`, { regions: [props.region] }, { ...authHeader });
    });
  };

  return {
    getCustomerById,
    getCustomerOrders,
    getCustomerScheduledTours,
    deleteCustomer,
    getCustomerInvoices,
    getCustomersForSelect,
  };
};
