import { FC } from 'react';
import { EmployeeResponseItem } from 'json-schemas/Employee.ResponseItem';

import { LiveIframe } from 'components/LiveIframe';

export const EmployeeDetailsDocuments: FC<{ employee: EmployeeResponseItem }> = (props) => {
  return (
    <LiveIframe
      styleKind="mainSection"
      path={`/employees/${props.employee.id}/accounting`}
      queryParams={{}}
    />
  )
};
